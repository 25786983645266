import React from "react";
import GridRow from "../../commons/GridRow";
import Heading from "../../commons/Heading";
import Box from "../../commons/Box";
import Flex from "../../commons/Flex";
import Image from "../../commons/Image";
import Button from "../../commons/Button";
import RichTextRenderer from "../../commons/RichTextRenderer";
import Card from "./Card";
import { linkResolver } from "../../../utils/links";
import { carouselStyles, fluid } from "../../../utils/styles";

export default function Grid2CardBlock({
  _rawTitle,
  cards,
  buttons,
  ...otherProps
}) {
  return (
    <Box
      as="section"
      sx={{
        pt: [4, 0],
        backgroundColor: "background",
      }}
    >
      <GridRow
        className="bg-circle"
        sx={{
          position: "relative",
          gridAutoRows: "min-content",
          py: [3, 5],
        }}
      >
        <GridRow.Col
          gridColumn={["start / end", "start / span 5", "start / span 4"]}
          sx={{ textAlign: ["center", "start"], pt: "gridGap" }}
        >
          <RichTextRenderer blocks={_rawTitle} />
          {!!buttons.length && (
            <Box sx={{ mt: 4 }}>
              {buttons.map(linkResolver).map((link) => (
                <Button
                  key={link.to || link.href}
                  variant="gradient"
                  {...link}
                />
              ))}
            </Box>
          )}
        </GridRow.Col>
        <GridRow.Col
          gridColumn={["1 / -1", "start / end", "6 / end", "7 / end"]}
          sx={{
            position: "relative",
            width: "100%",
            pt: [0, 0, 5],
          }}
        >
          <Flex
            sx={{
              flexWrap: ["nowrap", "wrap"],
              justifyContent: ["start", "center"],
              overflow: ["auto", "visible"],
              mx: [0, -2],
              "&:after": {
                content: "''",
                flex: "0 0 1px",
              },
              ...carouselStyles,
            }}
          >
            {cards.map((card, i) => (
              <Box
                key={card._key}
                sx={{
                  width: ["88%", "33.33%"],
                  maxWidth: [325, 260],
                  flexShrink: 0,
                  scrollSnapAlign: "center",
                  ml: ["gridGap", 0],
                  p: [0, 2],
                  "&:last-child": {
                    mr: ["gridGap", 0],
                  },
                }}
              >
                <Card index={i}>
                  {card.image && card.image.asset && (
                    <Box
                      sx={{ flexShrink: 0, mt: -3, width: "80%", mx: "auto" }}
                    >
                      <Image
                        alt={card.image.alt}
                        fluid={{
                          ...card.image.asset.fluid,
                          // override aspect ratio so image is always squared
                          aspectRatio: 1,
                        }}
                        sx={{
                          maxHeight: "100%",
                          borderRadius: card.image.asset.metadata.isOpaque
                            ? "circle"
                            : 0,
                        }}
                        imgStyle={{ objectFit: "contain" }}
                      />
                    </Box>
                  )}
                  <Heading as="h3" variant="h5" sx={{ color: "white" }}>
                    {card.title}
                  </Heading>
                  <Box
                    sx={(theme) => ({
                      mt: 3,
                      maxWidth: 260,
                      mx: "auto",
                      fontSize: fluid(
                        theme.fontSizes[2],
                        theme.fontSizes[3],
                        theme.breakpoints.minSize,
                        theme.breakpoints.maxSize
                      ),
                    })}
                  >
                    <RichTextRenderer blocks={card._rawDescription} />
                  </Box>
                </Card>
              </Box>
            ))}
          </Flex>
        </GridRow.Col>
      </GridRow>
    </Box>
  );
}
