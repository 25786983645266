import React from "react";
import { useThemeUI } from "@theme-ui/core";
// import { easeIn } from "@popmotion/easing";

import Box from "../../commons/Box";

export default function Card({ index, ...otherProps }) {
  const { theme } = useThemeUI();

  return (
    <Box
      as="section"
      sx={{
        display: "flex",
        flexDirection: "column",
        borderRadius: "card",
        boxShadow: "card",
        p: 3,
        color: "white",
        height: "100%",
        textAlign: "center",
      }}
      style={{
        background: `linear-gradient(${index % 2 ? "225deg" : "-45deg"}, ${
          theme.colors.blue
        } 0%, ${theme.colors.red} 100%)`,
      }}
      {...otherProps}
    />
  );
}
